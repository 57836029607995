<nav class="navbar navbar-vertical navbar-expand-md navbar-light bg-white" id="sidenav-main">
  <div class="container-fluid">
    <!-- Toggler -->
    <button class="navbar-toggler" type="button" (click)="isCollapsed=!isCollapsed"
       aria-controls="sidenav-collapse-main">
      <span class="navbar-toggler-icon"></span>
    </button>
    <!-- Brand -->
    <a class="navbar-brand pt-0" routerLinkActive="active" [routerLink]="['/dashboard']">
      <img src="{{ user?.clientLogo ? user?.clientLogo : 'https://dms-info.s3.ap-south-1.amazonaws.com/nextg/full_logo.png' }}" class="navbar-brand-img" alt="...">
    </a>
    <!-- User -->
    <ul class="nav align-items-center d-md-none">
      <!-- <li class="nav-item" ngbDropdown placement="bottom-right">
        <a class="nav-link nav-link-icon" role="button" ngbDropdownToggle>
          <i class="ni ni-bell-55"></i>
        </a>
        <div class="dropdown-menu-arrow dropdown-menu-right" ngbDropdownMenu>
          <a class="dropdown-item" href="javascript:void(0)">Action</a>
          <a class="dropdown-item" href="javascript:void(0)">Another action</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="javascript:void(0)">Something else here</a>
        </div>
      </li> -->
      <li class="nav-item" ngbDropdown placement="bottom-right">
        <a class="nav-link" role="button" ngbDropdownToggle>
          <div class="media align-items-center">
            <span class="avatar avatar-sm rounded-circle">
              <img alt="Image placeholder" src="{{ user?.image ? (path + 'storage-user/' + user?.image) : 'assets/img/145867.svg'}}">
            </span>
          </div>
        </a>
        <div class="dropdown-menu-arrow dropdown-menu-right" ngbDropdownMenu>
          <div class=" dropdown-header noti-title">
            <h6 class="text-overflow m-0">Welcome!</h6>
          </div>
          <a routerLinkActive="active" [routerLink]="['/user-profile']" class="dropdown-item">
            <i class="ni ni-single-02"></i>
            <span>My profile</span>
          </a>
          <!-- <a routerLinkActive="active" [routerLink]="['/user-profile']" class="dropdown-item">
            <i class="ni ni-settings-gear-65"></i>
            <span>Settings</span>
          </a>
          <a routerLinkActive="active" [routerLink]="['/user-profile']" class="dropdown-item">
            <i class="ni ni-calendar-grid-58"></i>
            <span>Activity</span>
          </a>
          <a routerLinkActive="active" [routerLink]="['/user-profile']" class="dropdown-item">
            <i class="ni ni-support-16"></i>
            <span>Support</span>
          </a> -->
          <div class="dropdown-divider"></div>
          <a href="javascript:void(0)" class="dropdown-item" (click)="logout()">
            <i class="ni ni-user-run"></i>
            <span>Logout</span>
          </a>
        </div>
      </li>
    </ul>
    <!-- Collapse -->
    <div class="collapse navbar-collapse"  [ngbCollapse]="isCollapsed" id="sidenav-collapse-main">
      <!-- Collapse header -->
      <div class="navbar-collapse-header d-md-none">
        <div class="row">
          <div class="col-6 collapse-brand">
            <a  routerLinkActive="active" [routerLink]="['/dashboard']">
              <img src="{{ user?.clientLogo ? user?.clientLogo : 'https://dms-info.s3.ap-south-1.amazonaws.com/nextg/full_logo.png' }}">
            </a>
          </div>
          <div class="col-6 collapse-close">
            <button type="button" class="navbar-toggler" (click)="isCollapsed=!isCollapsed">
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
      </div>
      <!-- Form -->
      <!-- <form class="mt-4 mb-3 d-md-none">
        <div class="input-group input-group-rounded input-group-merge">
          <input type="search" class="form-control form-control-rounded form-control-prepended" placeholder="Search" aria-label="Search">
          <div class="input-group-prepend">
            <div class="input-group-text">
              <span class="fa fa-search"></span>
            </div>
          </div>
        </div>
      </form> -->
      <!-- Navigation -->
      <ul class="navbar-nav">
        <li *ngFor="let item of menuItems" class="{{item.class}} nav-item">
          <a *ngIf="!item.hasChildren" routerLinkActive="active" [routerLink]="[item.path]" class="nav-link">
            <i class="ni {{item.icon}}"></i>
            {{item.title}}
          </a>
          <a *ngIf="item.hasChildren" href="javascript:void(0)" routerLinkActive="active" class="nav-link" (click)="item.collapsed = !item.collapsed" data-target=".training" data-toggle="collapse" [attr.aria-expanded]="!item.collapsed">
            <i class="ni {{item.icon}}"></i>
            {{ item.title }}
          </a>
          <ul *ngIf="item.hasChildren && !item.collapsed" class="nav nav-list" >
            <li *ngFor="let child of item.children" class="{{child.class}} nav-item">
              <a routerLinkActive="active" [routerLink]="[child.path]" class="nav-link">
                <i class="ni {{child.icon}}"></i>
                {{child.title}}
              </a>
            </li>
          </ul>
        </li>
        <!-- <li class="nav-item">
          <a href="javascript:void(0)" routerLinkActive="active" class="nav-link" (click)="trainingCollapsed = !trainingCollapsed" data-target="#training" data-toggle="collapse" [attr.aria-expanded]="!trainingCollapsed">
            <i class="ni ni-tv-2 text-primary"></i>
            Training
          </a>
          <ul class="nav nav-list" *ngIf="!trainingCollapsed" id="training">
            <li *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item">
              <a routerLinkActive="active" [routerLink]="[menuItem.path]" class="nav-link">
                <i class="ni {{menuItem.icon}}"></i>
                {{menuItem.title}}
              </a>
            </li>
          </ul>
        </li> -->
      </ul>
      <!-- Divider -->
      <hr class="my-3">
      <!-- Heading -->
      <!-- <h6 class="navbar-heading text-muted">Documentation</h6> -->
      <!-- Navigation -->
      <!-- <ul class="navbar-nav mb-md-3">
        <li class="nav-item">
          <a class="nav-link" href="https://demos.creative-tim.com/argon-dashboard-angular/documentation/tutorial">
            <i class="ni ni-spaceship"></i> Getting started
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://demos.creative-tim.com/argon-dashboard-angular/documentation/colors">
            <i class="ni ni-palette"></i> Foundation
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://demos.creative-tim.com/argon-dashboard-angular/documentation/alerts">
            <i class="ni ni-ui-04"></i> Components
          </a>
        </li>
      </ul> -->
    </div>
  </div>
</nav>
