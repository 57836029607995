import { Component } from '@angular/core';
import { SignalRService } from './services/signal-r/signal-r.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'argon-dashboard-angular';

  constructor(
    // private signalRService: SignalRService
  ) { }
}
