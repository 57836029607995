import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  // path = 'http://localhost:3030/';
  // path = 'http://13.71.17.57/api/CRUD_APi/';
  // reportingPath = 'http://13.71.17.57/api/Reporting_API/';

  // http://103.7.181.121:81/api/CRUD_APi/getreportdata
  
  // path = 'https://naiplwebapps01.azurewebsites.net/api/CRUD_APi/';
  // reportingPath = 'https://naiplwebapps01.azurewebsites.net/api/Reporting_API/';

  path = 'http://103.7.181.121:81/api/CRUD_APi/';
  reportingPath = 'http://103.7.181.121:81/api/Reporting_API/';

  constructor(
    private http: HttpClient,
  ) { }

  fileUpload(data, location = '') {
    return this.http.post(this.path + 'upload' + location, data, {
      observe: 'response'
    });
  }
}
