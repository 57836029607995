import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from '../../services/http/http.service';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    hasChildren: boolean;
    collapsed: boolean;
    children: RouteInfo[];
    roles: number[];
}
export const ROUTES: RouteInfo[] = [
    // tslint:disable-next-line:max-line-length
    // { path: '/dashboard', title: 'Dashboard',  icon: 'ni-chart-pie-35 text-primary', class: '', hasChildren: false, collapsed: true, children: [] },
    {
      path: 'dashboard/',
      title: 'Dashboards',
      icon: 'ni-chart-pie-35 text-primary',
      class: '',
      hasChildren: true,
      collapsed: true,
      children: [
        // tslint:disable-next-line:max-line-length
        // { path: '/dashboard/attendance', title: 'Attendance',  icon: 'ni-chart-pie-35 text-primary', class: '', hasChildren: false, collapsed: true, children: [] },
        // tslint:disable-next-line:max-line-length
        // { path: '/dashboard/ccs', title: 'CCS',  icon: 'ni-chart-pie-35 text-primary', class: '', hasChildren: false, collapsed: true, children: [] },
        { path: '/dashboard/powerbi-attendance', title: 'Attendance',  icon: 'ni-chart-pie-35 text-primary', class: '', hasChildren: false, collapsed: true, children: [], roles: [29,39,40,42,44,72,133,134,125,135,136,146,147,148,163,186,189,202,232,249,261,263] },
        { path: '/dashboard/powerbi-sales', title: 'Sales',  icon: 'ni-chart-pie-35 text-primary', class: '', hasChildren: false, collapsed: true, children: [], roles: [29,39,40,42,44,72,133,134,125,135,136,146,147,148,163,186,189,202,232,249,261,263] },
        { path: '/dashboard/powerbi-feedback', title: 'Feedback',  icon: 'ni-chart-pie-35 text-primary', class: '', hasChildren: false, collapsed: true, children: [], roles: [29,39,40,42,44,133,134,125,135,136,146,147,148,163,186,189,202,232,249,261,263] },
        { path: '/dashboard/powerbi-stock', title: 'Stock',  icon: 'ni-chart-pie-35 text-primary', class: '', hasChildren: false, collapsed: true, children: [], roles: [29,39,40,42,44,72,133,134,125,135,136,146,147,148,163,186,189,202,232,249,261,263] },
        // { path: '/dashboard/powerbi-sale_analysis', title: 'Sales Analysis',  icon: 'ni-chart-pie-35 text-primary', class: '', hasChildren: false, collapsed: true, children: [], roles: [29,39,40,42,44] },

      ],
      roles: [29,39,40,42,44,133,134,125,135,136,146,147,148,163,186,189,202,232,249,261,263]
    },
    // { path: '/lead', title: 'Leads',  icon: 'ni-tv-2 text-orange', class: '', hasChildren: false, collapsed: true, children: [] },
    // tslint:disable-next-line:max-line-length
    //{ path: '/task', title: 'Tasks',  icon: 'ni-bullet-list-67 text-pink', class: '', hasChildren: false, collapsed: true, children: [] },
    //{ path: '/training', title: 'Training',  icon: 'ni-books text-brown', class: '', hasChildren: false, collapsed: true, children: [] },
    // { path: '/users', title: 'User Mapping',  icon: 'fas fa-users text-info', class: '', hasChildren: false, collapsed: true, children: [] },
    { path: '/clients', title: 'Clients',  icon: 'fas fa-users text-info', class: '', hasChildren: false, collapsed: true, children: [], roles: [29] },
    { path: '/store-master', title: 'Stores',  icon: 'fas fa-store text-default', class: '', hasChildren: false, collapsed: true, children: [], roles: [29,39,134,135,136,146,147,163,186,189,202,232,249,261,263] },
    {
      path: 'product-master/',
      title: 'Product Master',
      icon: 'fas fa-box-open text-primary',
      class: '',
      hasChildren: true,
      collapsed: true,
      children: [
        { path: '/product-master/brand', title: 'Brands',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [], roles: [29] },
        { path: '/product-master/category', title: 'Categories',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [], roles: [29] },
        { path: '/product-master/product', title: 'Products',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [], roles: [29] },
      ],
      roles: [29]
    },
    {
      path: 'employee-master/',
      title: 'Employee Master',
      icon: 'fas fa-user-tie text-danger',
      class: '',
      hasChildren: true,
      collapsed: true,
      children: [
        { path: '/employee-master/position', title: 'Positions',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [], roles: [29] },
        { path: '/employee-master/employee', title: 'Employees',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [], roles: [29] },
      ],
      roles: [29]
    },
    {
      path: 'mapping/',
      title: 'Mapping',
      icon: 'fas fa-link text-warning',
      class: '',
      hasChildren: true,
      collapsed: true,
      children: [
        // { path: '/mapping/store-position', title: 'Store to Position',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },
        { path: '/mapping/callcycle', title: 'Callcycle',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [], roles: [29] },
      ],
      roles: [29]
    },
    {
      path: 'reports/',
      title: 'Reports',
      icon: 'fas fa-table text-success',
      class: '',
      hasChildren: true,
      collapsed: true,
      children: [
        // tslint:disable-next-line:max-line-length
        // { path: '/reports/lead-allocation', title: 'Lead Allocation',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },
        // tslint:disable-next-line:max-line-length
        { path: '/report/attendance', title: 'Attendance',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [], roles: [29,39,40,42,44,72,133,134,125,135,136,146,147,148,163,186,189,202,232,249,261,263] },
        // tslint:disable-next-line:max-line-length
        { path: '/report/mtd-attendance', title: 'MTD Attendance',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [], roles: [29,39,40,42,44,72,133,134,125,135,136,146,147,148,163,186,189,202,232,249,261,263] },
        // tslint:disable-next-line:max-line-length
        // { path: '/reports/attendance-signoff', title: 'Attendance Signoff',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },
        // tslint:disable-next-line:max-line-length
        // { path: '/reports/ccs', title: 'CCS',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },
        
        //{ path: '/reports/ccs-ba', title: 'CCS (BA)',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },
        //{ path: '/reports/ccs-bm', title: 'CCS (BM)',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },

        // tslint:disable-next-line:max-line-length
        // { path: '/reports/oos', title: 'OOS',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },
        // { path: '/reports/oos-details', title: 'OOS Details',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },

        // tslint:disable-next-line:max-line-length
        // { path: '/reports/tester', title: 'Tester',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },
        // tslint:disable-next-line:max-line-length
        // { path: '/reports/ba-evaluation', title: 'BA Evaluation',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },
        // tslint:disable-next-line:max-line-length
        // { path: '/reports/visitor-login', title: 'Visitor Login',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },
		    // tslint:disable-next-line:max-line-length
        // { path: '/reports/makeover-ccs-summary', title: 'Makeover + CCS (Summary)',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },
		    // tslint:disable-next-line:max-line-length
        // { path: '/reports/makeover-ccs-detail', title: 'Makeover + CCS (Detail)',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },
        // { path: '/reports/makeover-consumables-detail', title: 'Makeover Consumables',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },
        // { path: '/reports/counter-consumables', title: 'Counter Consumables',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },
        // { path: '/reports/sampling', title: 'Sampling',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },
        // { path: '/reports/feedback', title: 'Feedback',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },
        // { path: '/reports/stock', title: 'Stock',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },
        // { path: '/reports/customer-feedback', title: 'Customer Feedback',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },
        // { path: '/reports/counter-hygiene', title: 'Counter Hygiene',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },
        // { path: '/reports/display-guidelines', title: 'Display Guidelines',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },
        // { path: '/reports/counter-maintenance', title: 'Counter Maintenance',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },
        // { path: '/reports/tester-requirement', title: 'Tester Requirement',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },
        // { path: '/reports/store-feedback', title: 'Store Feedback',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },
        // { path: '/reports/visitor-login', title: 'Visitor Login',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },
        // { path: '/reports/target-vs-achievement', title: 'Target vs Achievement',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },
        // { path: '/reports/ba-summary', title: 'BA Summary',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },
        // { path: '/reports/users-activated', title: 'Users Activated',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },
        // { path: '/reports/sale', title: 'Sales',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },
        // { path: '/reports/compliance', title: 'Compliance',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },

        // tslint:disable-next-line:max-line-length
        // { path: '/reports/promoter-gromming', title: 'Promoter Gromming',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },
        // tslint:disable-next-line:max-line-length
        // { path: '/reports/lead-conversion', title: 'Lead Conversion',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },
        // tslint:disable-next-line:max-line-length
        // { path: '/reports/outlet-detail', title: 'Outlet Detail',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },
        // tslint:disable-next-line:max-line-length
        // { path: '/reports/market-working', title: 'Market Working',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },
        // tslint:disable-next-line:max-line-length
        // { path: '/reports/user-activity', title: 'User Activity',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },
        // tslint:disable-next-line:max-line-length
        { path: '/report/checkin-checkout', title: 'Check IN - Check Out',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [], roles: [29,39,40,42,44,72,133,134,125,135,136,146,147,148,163,186,189,202,232,249,261,263] },
        { path: '/report/stock', title: 'Stock',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [], roles: [29,39,40,42,44,72,133,134,125,135,136,146,147,148,163,186,189,202,232,249,261,263] },
        { path: '/report/sales', title: 'Sales',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [], roles: [29,39,40,42,44,72,133,134,125,135,136,146,147,148,163,186,189,202,232,249,261,263] },
        { path: '/report/so-sales', title: 'SO Sales',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [], roles: [29,40,134] },
        { path: '/report/customer-feedback', title: 'Customer Feedback',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [], roles: [29,39,40,42,44,72,133,134,125,135,136,146,147,148,163,186,189,202,232,249,261,263] },
        { path: '/report/selling-price', title: 'Selling Price',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [], roles: [29,39,40,42,44,72,133,134,125,135,136,146,147,148,163,186,189,202,232,249,261,263] },
        { path: '/report/target-vs-achievement', title: 'Target vs Achievement',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [], roles: [29,39,40,42,44,72,133,134,125,135,136,146,147,148,163,186,189,202,232,249,261,263] },
        { path: '/report/competitor-selling-price', title: 'Competitor Selling Price',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [], roles: [29,39,40,42,44,72,133,134,125,135,136,146,147,148,163,186,189,202,232,249,261,263] },
        { path: '/report/StockSummary', title: 'Stock Summary',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [], roles: [29,39,133,134,125,135,136,146,147,148,163,186,189,202,232,249,261,263] },
      ],
      roles: [29,39,40,42,44,72,133,134,125,135,136,146,147,148,163,186,189,202,232,249,261,263]
    },
    // {
    //   path: 'reports/',
    //   title: 'FM Reports',
    //   icon: 'fas fa-tachometer-alt text-warning',
    //   class: '',
    //   hasChildren: true,
    //   collapsed: true,
    //   children: [
    //     // tslint:disable-next-line:max-line-length
    //     // { path: '/reports/lead-allocation', title: 'Lead Allocation',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },
    //     // tslint:disable-next-line:max-line-length
    //     { path: '/reports/checkin-checkout', title: 'Check IN - Check Out',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },
    //     // tslint:disable-next-line:max-line-length
    //     { path: '/reports/ccs-summary', title: 'CCS Summary',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },
    //     // tslint:disable-next-line:max-line-length
    //     { path: '/reports/lead-by-example', title: 'Lead By Example',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [] },
    //   ]
    // },
    // tslint:disable-next-line:max-line-length
    // { path: '/reports/user-master', title: 'User Master',  icon: 'fas fa-users text-primary', class: '', hasChildren: false, collapsed: true, children: [] },
    //{ path: '/reports/new', title: 'Users',  icon: 'fas fa-users text-primary', class: '', hasChildren: false, collapsed: true, children: [] },
    // tslint:disable-next-line:max-line-length
    { path: '/gallery', title: 'Gallery',  icon: 'far fa-images text-info', class: '', hasChildren: false, collapsed: true, children: [], roles: [29,39,40,42,44,72,133,134,125,135,136,146,147,148,163,186,189,202,232,249,261,263] },
    // { path: '/icons', title: 'Icons',  icon: 'ni-planet text-blue', class: '' },
    // { path: '/maps', title: 'Maps',  icon: 'ni-pin-3 text-orange', class: '' },
    // { path: '/user-profile', title: 'User profile',  icon: 'ni-single-02 text-yellow', class: '' },
    // { path: '/tables', title: 'Tables',  icon: 'ni-bullet-list-67 text-red', class: '' },
    // { path: '/login', title: 'Login',  icon: 'ni-key-25 text-info', class: '' },
    // { path: '/register', title: 'Register',  icon: 'ni-circle-08 text-pink', class: '' },
    // // {
    // //   path: 'masters/',
    // //   title: 'Masters',
    // //   icon: 'fas fa-tachometer-alt text-success',
    // //   class: '',
    // //   hasChildren: true,
    // //   collapsed: true,
    // //   children: [
    // //     { path: '/users', title: 'Users',  icon: 'fas fa-users text-info', class: '', hasChildren: false, collapsed: true, children: [] },
    // //   ]
    // // },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public menuItems: any[];
  public isCollapsed = true;
  user: any;
  path = '';
  trainingCollapsed = true;

  constructor(
    private router: Router,
    private httpService: HttpService
  ) {
    this.path = this.httpService.path;
    this.user = JSON.parse(localStorage.getItem('user'))[0];
  }

  // ngOnInit() {
  //   this.menuItems = ROUTES.filter(menuItem => menuItem);
  //   this.router.events.subscribe((event) => {
  //     this.isCollapsed = true;
  //  });
  // }

  ngOnInit() {
    this.menuItems = ROUTES.map((menuItem: RouteInfo) => {
      if (menuItem.hasChildren) {
        if (menuItem.roles.includes(this.user.userRoleID)) {
          menuItem.children = menuItem.children.map((subMenuItem: RouteInfo) => {
            if (subMenuItem.roles.includes(this.user.userRoleID)) {
              return subMenuItem;
            }
          }).filter(subMenuItem => subMenuItem !== undefined);
          return menuItem;
        }
      } else {
        if (menuItem.roles.includes(this.user.userRoleID)) {
          return menuItem;
        }
      }
    }).filter(menuItem => menuItem !== undefined);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
   });
  }

  logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
  }
}
