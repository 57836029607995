import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import * as jwt_decode from 'jwt-decode';
import { HttpService } from '../http/http.service';
// import { FeathersService } from './feathers.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    // private feathers: FeathersService
  ) { }

  login(phone, pwd) {
    let params = new HttpParams();
    params = params.set('User', String(phone));
    params = params.set('Pass', String(pwd));
    return this.http.get(this.httpService.path + 'getdo_login', {
      params: params,
      observe: 'response'
    }).pipe(
      tap(
        resp => {
          if (resp.body[0]['Response'] != 'No') {
            localStorage.setItem('user', JSON.stringify(resp.body));
            localStorage.setItem('uid', resp.body[0]['User_ID']);
            localStorage.setItem('feathers-jwt', '');
            localStorage.setItem('token', resp.body[0]['Token_ID']);
          }
          // if (resp.status === 201) {
          //   localStorage.setItem('user', JSON.stringify(resp.body));
          //   localStorage.setItem('feathers-jwt', resp.body['accessToken']);
          //   localStorage.setItem('token', resp.body['accessToken']);
          //   this.feathers._socket.emit('authenticate', {
          //     strategy: 'jwt',
          //     accessToken: resp.body['accessToken']
          //   }, function(message, dta) {
          //     console.log(message); // message will be null
          //     console.log(dta); // data will be {"accessToken": "your token"}
          //     // You can now send authenticated messages to the server
          //   });
          // }
        }
      )
    );
  }

  register(data) {
    return this.http.post(this.httpService.path + 'users/', data, {
      observe: 'response'
    });
  }

  createUser(data) {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token')
    });
    return this.http.post(this.httpService.path + 'users/', data, {
      headers: headers,
      observe: 'response'
    });
  }

  getRoles() {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token')
    });
    return this.http.get(this.httpService.path + 'roles/?$limit=100&$sort[id]=1', {
      headers: headers,
      observe: 'response'
    });
  }

  getUser(id) {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token')
    });
    return this.http.get(this.httpService.path + 'users/' + id, {
      headers: headers,
      observe: 'response'
    });
  }

  getUsers() {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token')
    });
    return this.http.get(this.httpService.path + 'users/', {
      headers: headers,
      observe: 'response'
    });
  }

  getUsersPaginated(limit: number = 10, skip: number = 0) {
    let params = new HttpParams();
    params = params.set('$limit', String(limit));
    params = params.set('$skip', String(skip));
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token')
    });
    return this.http.get(this.httpService.path + 'users/', {
      params: params,
      headers: headers,
      observe: 'response'
    });
  }

  changeUserStatus(id, status) {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token')
    });
    return this.http.patch(this.httpService.path + 'users/' + id, {enabled: status}, {
      headers: headers,
      observe: 'response'
    });
  }

  updateUser(id, data) {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token')
    });
    return this.http.patch(this.httpService.path + 'users/' + id, data, {
      headers: headers,
      observe: 'response'
    });
  }

  getPincodes() {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token')
    });
    return this.http.get(this.httpService.path + 'pincodes/', {
      headers: headers,
      observe: 'response'
    });
  }
  getPincode(id) {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token')
    });
    return this.http.get(this.httpService.path + 'pincodes/' + id, {
      headers: headers,
      observe: 'response'
    });
  }

  getPincodeDetail(pincode) {
    let params = new HttpParams();
    params = params.set('[pincode][$like]', pincode + '%');
    params = params.set('$limit', '3');
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token')
    });
    return this.http.get(this.httpService.path + 'pincodes/', {
      params: params,
      headers: headers,
      observe: 'response'
    });
  }

  searchUser(text, roles: any[]) {
    let params = new HttpParams();
    if (!isNaN(text)) {
      params = params.set('$or[0][phone]', text);
    }
    params = params.set('$or[1][first_name][$like]', '%' + text + '%');
    params = params.set('$or[2][last_name][$like]', '%' + text + '%');
    params = params.set('$or[3][email][$like]', '%' + text + '%');
    params = params.set('$limit', '3');
    if (roles.length > 0) {
      params = params.set('[role_id][$in]', JSON.stringify(roles));
    }
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token')
    });
    return this.http.get(this.httpService.path + 'users/', {
      params: params,
      headers: headers,
      observe: 'response'
    });
  }

  allocatePincode(data) {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token')
    });
    return this.http.post(this.httpService.path + 'pincode-allocation/', data, {
      headers: headers,
      observe: 'response'
    });
  }

  allocateUser(data) {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token')
    });
    return this.http.post(this.httpService.path + 'user-allocation/', data, {
      headers: headers,
      observe: 'response'
    });
  }

  updatePincodeAllocation(id, data) {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token')
    });
    return this.http.patch(this.httpService.path + 'pincode-allocation/' + id, data, {
      headers: headers,
      observe: 'response'
    });
  }

  getUserPincodes(id) {
    let params = new HttpParams();
    params = params.set('user_id', id);
    params = params.set('deleted', 'false');
    params = params.set('end_date[$gte]', new Date().toISOString());
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token')
    });
    return this.http.get(this.httpService.path + 'pincode-allocation/', {
      params: params,
      headers: headers,
      observe: 'response'
    });
  }


  // Check token
  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);

    if (decoded.exp === undefined) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(token?: string): boolean {
    if (!token) {
      token = localStorage.getItem('token');
    }
    if (!token) {
      return true;
    }
    const date = this.getTokenExpirationDate(token);
    console.log(date);
    if (date === undefined || date === null) {
      return false;
    }
    return !(date.valueOf() > new Date().valueOf());
  }
}
