import { Component, OnInit, ElementRef } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { HttpService } from '../../services/http/http.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  public focus;
  public listTitles: any[];
  public location: Location;
  user: any;
  path = '';

  constructor(
    location: Location,
    private element: ElementRef,
    private router: Router,
    private httpService: HttpService
  ) {
    this.path = this.httpService.path;
    this.user = JSON.parse(localStorage.getItem('user'))[0];
    this.location = location;
  }

  ngOnInit() {
    this.listTitles = ROUTES.filter(listTitle => listTitle);
  }

  getTitle() {
    let titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice( 2 );
    }
    if (!titlee.includes('/')) {
      titlee = '/' + titlee;
    }
    for (let item = 0; item < this.listTitles.length; item++) {
      if (titlee.includes(this.listTitles[item].path)) {
        if (this.listTitles[item].hasChildren) {
          let ret = this.listTitles[item].title + ' / ';
          for (let i = 0; i < this.listTitles[item].children.length; i++) {
            if (titlee == this.listTitles[item].children[i].path.slice(1)) {
              ret += this.listTitles[item].children[i].title;
              return ret;
            }
          }
        } else {
          return this.listTitles[item].title;
        }
      }
    }
    return 'Dashboard';
  }

  reload() {
    window.location.reload(true);
  }

  logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('uid');
    localStorage.removeItem('feathers-jwt');
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
  }

}
